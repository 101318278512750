* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171920;
  color: white;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

input {
  user-select: text;
  -o-user-select: text;
  -ms-user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}

canvas {
  cursor: grab;
}
canvas:active {
  cursor: grabbing;
}
button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.main {
  width: calc(100% - 10rem);
  height: 100vh;
  margin-left: 10rem;
  /* scroll-snap-type: y mandatory; */
  overflow-y: scroll;
}

.container {
  min-height: 100vh;
  /* scroll-snap-align: start; */
  overflow: auto;
}

.contact-container {
  height: auto;
  /* scroll-snap-align: start; */
  overflow: hidden;
}

.footer-container {
  height: auto;
  /* scroll-snap-align: start; */
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.threed-container {
  width: calc(100% - 10rem);
}

.home {
  height: 100vh;
  background-image: url("../src/assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.mobile {
  display: none;
}
.menu-icon {
  color: #ffffffc7;
}

.footer {
  height: 15vh;
  color: #fff6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.highlight {
  color: #fff;
}
input:-internal-autofill-selected {
  background: #131313;
}
.t {
  cursor: default;
}
.t:hover {
  color: #667dff;
}
.contact-icon:hover {
  color: #667dff;
}
.companyname {
  color: #667dff;
  font-weight: bold;
  cursor: pointer;
}
.color {
  color: #667dff;
}
.skill-label {
  font-size: 1.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.mobile-skill-label {
  font-size: 1.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 2rem;
  margin-top: 1rem;
}
.skill-info {
  font-size: 1.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 2rem;
}
.personal-info {
  font-size: 1.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.m-15 {
  margin-right: 15px;
}
.m-215 {
  margin-right: 15px;
  margin-left: 40px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-icon {
  margin-left: 5px;
  width: 18px !important;
}
.view-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  outline: none;
  border: none;
  padding: 0px 30px;
  border-radius: 40px;
  background: #d97604;
  margin-bottom: 10px;
  cursor: pointer;
}
.github-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  outline: none;
  border: none;
  padding: 0px 30px;
  border-radius: 40px;
  background: #795fa1;
  margin-bottom: 10px;
  cursor: pointer;
}
.error-btn {
  display: flex;
  margin-top: 12px;
  color: #fff;
  font-size: 1.3rem;
  outline: none;
  border: none;
  padding: 10px 30px;
  border-radius: 40px;
  background: #c74244;
  box-shadow: 0 0 10px #ed4e51;
  margin-bottom: 10px;
  cursor: not-allowed;
}
.sending-btn {
  display: flex;
  margin-top: 12px;
  color: #fff;
  font-size: 1.3rem;
  outline: none;
  border: none;
  padding: 10px 30px;
  border-radius: 40px;
  background: #0466d6;
  box-shadow: 0 0 10px #0678fb;
  margin-bottom: 10px;
  cursor: not-allowed;
}
.sent-btn {
  display: flex;
  margin-top: 12px;
  color: #fff;
  font-size: 1.3rem;
  outline: none;
  border: none;
  padding: 10px 30px;
  border-radius: 40px;
  background: #4ba155;
  box-shadow: 0 0 10px #64d772;
  margin-bottom: 10px;
  cursor: default;
}
.send-btn {
  display: flex;
  margin-top: 12px;
  color: #fff;
  font-size: 1.3rem;
  outline: none;
  border: none;
  padding: 10px 30px;
  border-radius: 40px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px #667dff;
  margin-bottom: 10px;
}
.send-btn:hover {
  background: #5029bc;
}
.send-arrow {
  margin-left: 15px;
}
.sending-circle {
  margin-left: 15px;
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
button:disabled,
button[disabled] {
  cursor: not-allowed;
  background-color: #929bb2;
  color: #fff;
  box-shadow: none;
}
.send-btn:disabled:hover,
.send-btn:disabled:focus,
.send-btn.disabled:hover,
.send-btn.disabled:focus {
  color: #fff;
  background-color: #929bb2;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-icon {
  margin: 0px 8px 10px 8px;
  transition: transform 0.2s;
}

.media-icon:hover {
  transform: scale(1.5);
}

.arjun-mob {
  max-width: 300px;
  margin: 0 auto;
  height: fit-content;
}

.overflow-auto {
  overflow: auto;
}

.publisher {
  font-size: 0.8rem;
  margin-bottom: 15px;
  color: #929bb2;
}

.show-publisher {
  background-color: #3e2093;
  color: #ffffff;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 1px 10px;
}

.pub-icon {
  width: 0.8rem !important;
  margin-bottom: -7px;
}

table {
  width: 100%;
}

td {
  vertical-align: top;
}

.sectionClass {
  padding: 50px 0px 50px 0px;
  position: relative;
  display: block;
}

.fullWidth {
  width: 100% !important;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}

.projectFactsWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#projectFacts .fullWidth {
  padding: 0;
}

.projectFactsWrap .item {
  width: 25%;
  padding: 50px 0px;
  text-align: center;
}

.projectFactsWrap .item:nth-child(1) {
  background: #272727;
}

.projectFactsWrap .item:nth-child(2) {
  background: #272727;
}

.projectFactsWrap .item:nth-child(3) {
  background: #272727;
}

.projectFactsWrap .item:nth-child(4) {
  background: #272727;
}

.projectFactsWrap .item p.number {
  font-size: 40px;
  padding: 0;
  font-weight: bold;
}

.projectFactsWrap .item p {
  color: white;
  font-size: 1.3rem;
  margin: 0;
  padding: 10px;
  font-family: "Open Sans";
}

.projectFactsWrap .item span {
  width: 60px;
  background: white;
  height: 2px;
  display: block;
  margin: 0 auto;
}

.projectFactsWrap .item .stats-icon {
  vertical-align: middle;
  font-size: 50px;
  color: white;
}
.projectFactsWrap .item:hover {
  background: #373737;
}

.projectFactsWrap .item:nth-child(1):hover .stats-icon,
.projectFactsWrap .item:nth-child(1):hover p {
  color: rgb(255, 71, 71);
}

.projectFactsWrap .item:nth-child(1):hover span {
  background: rgb(255, 71, 71);
}

.projectFactsWrap .item:nth-child(2):hover .stats-icon,
.projectFactsWrap .item:nth-child(2):hover p {
  color: rgb(3, 196, 3);
}

.projectFactsWrap .item:nth-child(2):hover span {
  background: rgb(3, 196, 3);
}

.projectFactsWrap .item:nth-child(3):hover .stats-icon,
.projectFactsWrap .item:nth-child(3):hover p {
  color: rgb(105, 204, 254);
}

.projectFactsWrap .item:nth-child(3):hover span {
  background: rgb(105, 204, 254);
}

.projectFactsWrap .item:nth-child(4):hover .stats-icon,
.projectFactsWrap .item:nth-child(4):hover p {
  color: rgb(255, 255, 95);
}

.projectFactsWrap .item:nth-child(4):hover span {
  background: rgb(255, 255, 95);
}

.proj-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.proj-card {
  border-radius: 15px;
  position: relative;
  margin: 20px 0;
  width: min(100% - 15px, 400px);
  height: 300px;
  margin-inline: auto;
  background: #fff;
  transform-style: preserve-3d;
  transform: perspective(2000px);
  transition: 1s;
  box-shadow: inset 100px 0 50px rgba(0, 0, 0, 0.5);
}

.proj-card:hover {
  z-index: 1111;
  transform: perspective(2000px);
  box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5);
}

.proj-card .img-container {
  border-radius: 15px;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transform-origin: left;
  z-index: 1;
  transition: 1s;
}

.blur-edges {
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  filter: blur(15px);
}

.proj-card .img-container img {
  border-radius: 15px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.proj-card:hover .img-container {
  transform: rotateY(-135deg);
}

.proj-card .card-details {
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 20px;
  color: #000;
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: justify;
  text-justify: inter-word;
}

.proj-card .card-details h2 {
  margin: 0;
  padding: 0.5em 0;
  font-size: 2em;
  color: #007bff;
}

.proj-card .card-details p {
  margin: 0;
  padding: 0;
  line-height: 25px;
  font-size: 1.1em;
}

@media (max-width: 786px) {
  .proj-card {
    width: min(100% - 15px, 200px);
    height: 150px;
  }
  .projectFactsWrap .item {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 1044px) {
  .proj-card {
    width: min(100% - 15px, 300px);
    height: 200px;
  }
  .main {
    width: 100vw;
    margin-left: 0;
  }
  .threed-container {
    width: 100vw;
    margin-left: 0;
  }
  .mobile {
    display: block;
  }
  .menu-icon {
    color: #ffffffb8;
    font-size: large;
    padding: 0.8rem;
    position: absolute;
    right: 0;
    z-index: 1000;
  }
  .menu-icon:hover {
    color: white;
  }
}
