.navbar {
  position: fixed;
  width: 10rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff0c;
  transform: all 1s;
  z-index: 1000;
  overflow-y: scroll;
}

.icon-close {
  display: none;
}

.logo {
  width: 100%;
  object-fit: cover;
  padding: 1.5rem 0.5rem;
  margin: 1rem 0;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  margin: 2rem 0px;
}

.nav-tile {
  display: flex;
  padding: 0.5rem 0;
}

.socials {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
  margin-top: auto;
}

@media only screen and (max-width: 1044px) {
  .icon-close {
    position: absolute;
    right: 0;
    display: block;
    padding: 0.8rem;
    color: white;
  }

  .navbar {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    display: none;
    background-color: #131313ee;
  }

  .logo {
    display: none;
  }

  .nav-buttons {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-tile {
    display: block;
    padding: 0.5rem 0;
  }

  .socials {
    display: none;
  }
}
